<template>
  <v-card color="transparent" flat v-if="getWizardStep === wizardStep.OFFER">
    <v-card-text class="text-center">
      <div
        v-if="getPrice === null"
        class="d-flex flex-column justify-center align-center pa-5"
        style="min-height: 300px"
      >
        <v-img
          lazy-src="https://mars-contents.fra1.digitaloceanspaces.com/dev/vehicle-image/covered-car"
          src="https://mars-contents.fra1.digitaloceanspaces.com/dev/vehicle-image/covered-car"
          max-width="300"
          contain
        ></v-img>
        <div v-if="calculateError" class="text-h6">
          Ön Fiyat teklifiniz oluşturulurken bir hata oluştu. Lütfen kısa bir süre sonra tekrar deneyiniz...
          <div>
            <v-btn
              :loading="calculateLoading"
              large
              shaped
              class="rounded-lg text-capitalize mt-5"
              @click="calculate"
              v-text="'Tekrar Dene'"
            ></v-btn>
          </div>
        </div>
        <div v-else>
          <div class="mb-5">
            <p class="text-body-1">
              {{ priceText1 }}
            </p>
          </div>
          <v-progress-circular indeterminate color="primary" size="80" width="5" class="mb-4"></v-progress-circular>
        </div>
      </div>
      <div v-else>
        <div class="text-body-1">
          {{ priceText1 }}
        </div>
        <div xs12 class="my-1">
          <div v-if="priceIsZero">
            <p class="text-h5">
              {{ noUpFrontPriceGiven }}
            </p>
          </div>
          <div v-else>
            <p class="text-h4 font-weight-bold mb-1">
              {{ getPrice | tl({ avoidEmptyDecimals: '' }) }}
            </p>
            <p class="text-h6">
              {{ turkisLiraText }}
            </p>
          </div>
        </div>
        <v-img class="img" :lazy-src="getCarImg" :src="getCarImg" contain> </v-img>
        <div class="my-1">
          <div class="details">
            <p>
              {{ getWizardCarInfo.year }} {{ getWizardCarInfo.brand.value }}
              {{ getWizardCarInfo.model.value }}
            </p>
            <p>
              {{ getWizardCarInfo.bodyType.value }}
              {{ getWizardCarInfo.fuelType.value }}
            </p>
            <p>{{ getWizardCarInfo.version.value }}</p>
            <p>{{ getWizardCarInfo.kilometers }} KM</p>
          </div>
        </div>
        <v-row>
          <v-col cols="6">
            <v-btn
              block
              :loading="loading"
              large
              outlined
              class="rounded-lg text-capitalize"
              color="grey"
              @click="continueProcess"
            >
              <v-icon left small v-text="'fas fa-backward'"></v-icon> Başa dön
            </v-btn>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <v-bottom-sheet v-model="garageSaveForm" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    :loading="loading"
                    large
                    outlined
                    class="rounded-lg text-capitalize"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left small v-text="'fas fa-car'"></v-icon> Garaja ekle
                  </v-btn>
                </template>
                <v-sheet class="text-center">
                  <v-card flat tile>
                    <v-card-title>
                      Aracı Kaydet
                      <v-spacer></v-spacer>
                      <v-btn @click="garageSaveForm = !garageSaveForm" icon>
                        <v-icon v-text="'fa-times'"></v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="offerForm.carTitle"
                            placeholder="Aracınıza bir isim verin"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-btn text block color="primary" @click="addGarage"> Kaydet </v-btn>
                    </v-card-text>
                  </v-card>
                </v-sheet>
              </v-bottom-sheet>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import wizardMixin from '@/mixins/wizardMixin';
import { mapActions, mapGetters } from 'vuex';
import {
  SET_TRACKING_NUMBER,
  CALCULATE_PRICE,
  RESET_STATE,
  CHANGE_WIZARD_STEP,
} from '@/store/modules/carWizard.module';

import { SAVE_FROM_WIZARD } from '@/store/modules/garage.module';

export default {
  name: 'WizardOffer',
  mixins: [wizardMixin],
  data() {
    return {
      garageSaveForm: false,
      calculateLoading: false,
      loading: false,
      calculateError: false,
      offerForm: {
        carTitle: null,
      },
    };
  },
  computed: {
    ...mapGetters(['getWizardCarInfo', 'getPrice', 'getCarImg']),
    priceIsZero() {
      return this.getPrice === 0;
    },
    priceText1() {
      return 'Aracınızın tahmini değeri aşağıda gösterilmektedir.';
    },
    noUpFrontPriceGiven() {
      return 'Bu araç için şuanda ön fiyat teklifi veremiyoruz.';
    },
    upFrontPriceTextHeader() {
      return 'Ön Fiyat Teklifimiz';
    },
    turkisLiraText() {
      return 'Türk Lirası';
    },
  },
  created() {
    this.$nextTick(() => {
      this.changeWizardStep(this.wizardStep.OFFER);
      this.calculate();
    });
  },
  methods: {
    ...mapActions({
      changeWizardStep: CHANGE_WIZARD_STEP,
      setTrackingNumber: SET_TRACKING_NUMBER,
      calculatePrice: CALCULATE_PRICE,
      resetState: RESET_STATE,
      saveFromWizard: SAVE_FROM_WIZARD,
    }),
    addGarage() {
      const payload = {
        carId: this.getWizardCarInfo.key,
        title: this.offerForm.carTitle,
      };
      this.saveFromWizard(payload).then(() => {
        this.garageSaveForm = !this.garageSaveForm;
        this.resetState();
        this.$router.push({ name: 'garage' });
      });
    },
    calculate() {
      if (typeof this.getPrice === 'number') return;

      this.calculateLoading = true;
      this.calculateError = false;

      this.calculatePrice()
        .then(() => {})
        .catch(() => {
          this.calculateError = true;
        })
        .finally(() => {
          this.calculateLoading = false;
        });
    },
    continueProcess() {
      this.resetState();
      this.$router.push({ name: 'wizardInfo' });
    },
  },
};
</script>

<style lang="scss" scoped></style>
